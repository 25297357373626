<template>
  <section class="buycourse pb-8" v-if="PageInfo.data.length !== 0">
    <div class="container">
      <!-- 課程列表 -->
      <article class="row cs-card" data-style="4">
        <!-- 課程商品 -->
        <div class="col-12 col-md-6 col-lg-4" v-for="item in PageInfo.data" :key="item.courseId">
          <div class="course__card card card-hover-focus mb-6 mb-md-8">
            <router-link :to="`/course/classRoom/${item.courseId}`">
              <div class="course__img bg-outer">
                <div
                  class="bg-image"
                  :style="{
                    backgroundImage: 'url(' + item.mainImagePath + ')',
                  }"
                ></div>
              </div>
              <div class="course__title">
                <h3>{{ item.courseName }}</h3>
              </div>
              <div class="card-body course__body text-center">
                <div class="course__price">原價 {{ item.courseOriPrice | toCurrency }}元</div>
                <div class="course__sale d-block">
                  <i class="fa fa-star mr-1" aria-hidden="true"></i>
                  特價 {{ item.coursePrice | toCurrency }} 元
                </div>
              </div>
            </router-link>
            <a class="course__btn-cart" href="" @click.prevent="AddToCar(item.courseId)"
              >加入購物車</a
            >
          </div>
        </div>
        <div></div>
      </article>
      <!-- 分頁 -->
      <Pagination
        @GetList="GetData"
        :page.sync="Pagination.pageIndex"
        :page-row="Pagination.pageSize"
        :total-count="PageInfo.count"
      />
    </div>
  </section>
  <section class="pb-8" v-else>
    <div class="container">
      <div class="error-bg mb-3 mb-md-8">
        <div>查無課程資料</div>
      </div>
    </div>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { GetBuyCourseSearch } from '@/api/BuyCourseApi';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      Pagination: {
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 6, // 預設每頁幾筆
        data: {
          searchContent: '',
        },
      },
      PageInfo: {
        count: 0, // api給的總筆數
        data: [],
      },
    };
  },
  watch: {
    $route() {
      this.GetData();
    },
  },
  created() {
    // ('對應傳送端的emit方法','接收的參數')
    this.$bus.$on('reSearchCourse', (str) => {
      this.GetData(str);
    });

    this.GetData();
  },
  beforeDestroy() {
    // 取消reSearchCourse事件定義
    this.$bus.$off('reSearchCourse');
  },
  methods: {
    GetData(str = '') {
      let searchContent = '';
      if (str === '') searchContent = this.$route.query.searchContent;
      else searchContent = str;
      this.Pagination.data.searchContent = searchContent;
      GetBuyCourseSearch(this.Pagination).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfo.count = response.data.count;
          this.PageInfo.data = response.data.data;
        }
      });
    },
    AddToCar(id) {
      this.$bus.$emit('addShoppingCart', id);
    },
  },
};
</script>

<style></style>
